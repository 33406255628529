import React from "react";
import { Box, Grid, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";

function Footer() {

  let navigate = useNavigate();

  return (
    <Box sx={{ bgcolor: 'black', pb: 1, pt: 3 }}>
      <Container>
        <Grid container spacing={0} alignItems="center" justifyContent="center" >
          <Grid container spacing={1} item xs={12} md={6} alignItems="center" justifyContent="center">
            <Grid item xs={12} md={4}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                Hevi embroidery
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
                onClick={() => {
                  navigate("/about");
                }}
              >
                About us
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography
                component="p"
                variant="p"
                sx={{
                  color: '#fff',
                  my: 1,
                  textAlign: 'center',
                  display: 'block',
                  transition: "2s ease-in-out",
                  cursor: "pointer",
                  textTransform: 'uppercase',
                  ":hover": { textDecoration: "underline" },
                }}
              >
                Contact us
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Typography
          variant="body2"
          sx={{
            color: '#fff',
            py: 2,
            textAlign: 'center',
            display: 'block',
          }}
        >
          © 2023 Hevi, All Rights Reserved.
        </Typography>
      </Container>
    </Box>
  );
}

export default Footer;
