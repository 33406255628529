import { get, post } from "../index.js";
import { Getaquoteroutes } from "./Getaquote.routes.js";

export const getaquoteservices = {
  getaquoteorder: async (data) => {
    const result = await post(Getaquoteroutes.postgetaquote, data);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
