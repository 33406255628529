import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import React from "react";
import PaypalForm from "./PaypalForm";

function PaypalContainer({ punchOrder, Total }) {
  return (
    <PayPalScriptProvider
      options={{
        "client-id":
          "ATA34CH_zDhuRLdXNmK8fhDkgBWDBVTVNPLynAV_my1m68pB4ubL_KXE0GbQoF6UZecE9PArGUUCOt8J",
      }}
    >
      <PaypalForm punchOrder={punchOrder} Total={Total} />
    </PayPalScriptProvider>
  );
}

export default PaypalContainer;
