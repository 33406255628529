import { PayPalRoutes } from "./PayPal.Routes";
import { get, post, deleted } from "../index.js";

export const PayPalServices = {
  postPayPal: async (Data) => {
    const result = await post(PayPalRoutes.PayPal, Data);
    if (result.status === 200) return result.data;
    else throw result;
  },
};
