import React from 'react'
import { Box, Grid, Typography } from '@mui/material'
import Footer from 'app/components/footer/footer'
import NavigationBar from 'app/components/navigation/navigationBar'
import Images from 'app/assets/images'

function About() {
  return (
    <>
      <Box>
        <NavigationBar />
      </Box>
      <Box sx={{ backgroundImage: `url(${Images.banner})`, width: 1, height: '500px', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover', }} />
      <Box sx={{ mt: 4, mb: 6 }}>
        <Grid container spacing={1} alignItems="center" justifyContent={"center"}>
          <Grid item md={6}>
            <Typography variant="h2">
              Who we are
            </Typography>
            <Typography variant="h6" sx={{ my: 1, color: '#333' }}>
              We are an embroidery company that focuses on small orders. We specialize in hoodies, hats, jackets, and bags. Our mission is to help our customers express themselves through customizable embroider apparel. Our Design Library contains original artwork as well as curated stock images that can be place on high quality apparel. We want you to stay original and to revel in your style.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Footer />
    </>
  )
}

export default About