const Images = {
  logo: require("./Images/Logo.png"),
  banner: require("./Images/banner.png"),
  artical1: require("./Images/artical1.jpg"),
  artical2: require("./Images/artical2.jpg"),
  artical3: require("./Images/artical3.jpg"),
  artical4: require("./Images/artical4.jpg"),
  artical5: require("./Images/artical5.jpg"),
  artical6: require("./Images/artical6.jpg"),
};
export default Images;