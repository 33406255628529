// Imports
import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";

import NavigationBar from "../../components/navigation/navigationBar";
import SwiperBanner from "../../components/swipper/swipper.js";
import ExploreCard from "../../components/Card/exploreCard/exploreCard";
import SampleCard from "../../components/Card/sampleCard/sampleCard";
import Footer from "../../components/footer/footer.js";
import { CardMedia, Grid, Tab, Tabs, Typography } from "@mui/material";

// Imports Swiper
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules

import { Autoplay, Navigation } from "swiper";
import { CategoriesServices } from "app/apis/Categories/CategoriesServices.js";
import {
  ErrorToaster,
  SuccessToaster,
} from "app/components/Toaster/toaster.js";
import Loader from "app/assets/Loader.js";
import Images from "app/assets/images.js";

function Home() {
  const [Categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productByCategory, setProductByCategory] = useState([]);
  console.log("🚀 productByCategory", productByCategory);
  // Sample Swiper Dummy Data
  let Data = [
    {
      id: 1,
      product_name: "abc",
      Description: "sajdjbn",
      qty: 100,
      price: 1212,
    },
  ];

  // getcategories---------->
  const getCategories = async () => {
    setLoading(true);
    try {
      let result = await CategoriesServices.getCategories();
      if (result.responseCode === 200) {
        const customizableCategory = result.data.filter(
          (item) => item.type === "Customizable"
        );
        setCategories(customizableCategory);
        console.log("customizableCategory=>", customizableCategory);
        getProductsById(customizableCategory[0].id);
      } else {
        ErrorToaster("Oops and Error Occue");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setLoading(false);
    }
  };

  // Get Prducts by iD------>
  const getProductsById = async (ID) => {
    console.log("🚀 ID:", ID);
    setProductsLoading(true);
    try {
      let result = await CategoriesServices.getProductByCategoryID(ID);
      if (result.responseCode === 200) {
        setProductByCategory(result.data);
        console.log("result=>", result);
      } else {
        ErrorToaster("Oops and Error Occur!");
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
      setProductsLoading(false);
    }
  };

  // Tabs Change
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    getCategories();
  }, [URL]);
  return (
    <>
      {/* Navbar -----------------------------------------------------> */}
      <Box
        sx={{
          md: "fi",
          color: { xs: "black" },
          ":hover": { color: "black", background: "white" },
        }}
        width="100%"
      >
        <NavigationBar />
      </Box>

      {/* Banner of  Pictures -------------------->*/}
      {/* <SwiperBanner /> */}
      <Box
        sx={{
          backgroundImage: `url(${Images.banner})`,
          width: 1,
          height: { xs: "220px", sm: "300px", md: "400px", lg: "500px" },
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: { xs: "contain", sm: "cover", md: "cover" },
        }}
      />

      {/* Categories Section-------------------------------------------->   */}
      <Box sx={{ padding: { lg: "40px", xs: "20px" }, paddingBottom: "0px" }}>
        <Box
          sx={{
            mb: {
              xs: "10px",
              md: "30px",
            },
          }}
        >
          <Typography
            component="p"
            variant="h3"
            sx={{
              display: "block",
              textTransform: "uppercase",
              textAlign: "center",
              fontSize: { md: "50px", xs: "15px" },
            }}
          >
            Stay Original
          </Typography>
          <Typography
            component="p"
            variant="p"
            sx={{
              display: "block",
              textAlign: "center",
              fontSize: { xs: "12px", md: "20px" },
            }}
          >
            Choose your blanks then add a custom design or choose one from our
            library
          </Typography>
        </Box>
        {/* Categories Section------>   */}
        {loading ? (
          <Box sx={{ height: { xs: "400px", md: "700px" } }}>
            <Loader />
          </Box>
        ) : (
          <>
            {/* Categories Tabs---------------> */}

            <Box display={"flex"} justifyContent="center">
              <Box
                sx={{
                  width: {
                    xs: "250px",
                    md: "900px",
                  },
                  padding: {
                    xs: "5px",
                    md: "10px",
                  },
                  bgcolor: "background.paper",
                  // border: "1px solid lightgray",
                }}
              >
                <Tabs
                  value={value}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                  sx={{
                    ".MuiButtonBase-root": {
                      fontSize: {
                        xs: "10px",
                        md: "20px",
                      },
                      color: "gray,",
                      fontWeight: "600",
                    },
                    ".css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected":
                      {
                        fontWeight: "700",
                        color: "black",
                        fontSize: {
                          xs: "10px",
                          md: "20px",
                        },
                      },
                    fontSize: "20px",
                  }}
                >
                  {Categories.map((Item) => (
                    <Tab
                      label={`${Item.name}`}
                      onClick={() => {
                        getProductsById(Item.id);
                      }}
                    />
                  ))}
                </Tabs>
              </Box>
            </Box>

            {/* Products of category */}
            <Grid container gap="0px" mt={"10px"}>
              {productByCategory.length !== 0 &&
                (productsLoading ? (
                  <Grid item xs="12" md="12" lg="12">
                    <Box sx={{ height: { xs: "400px", md: "700px" } }}>
                      <Loader />
                    </Box>
                  </Grid>
                ) : (
                  productByCategory.map((Item) => {
                    return (
                      <Grid item xs="12" lg="3">
                        <Box sx={{ padding: "10px" }}>
                          <SampleCard
                            name={Item.product_name}
                            Data={Item}
                            description={Item.description}
                            img={Item.product_image}
                          />
                        </Box>
                      </Grid>
                    );
                  })
                ))}
            </Grid>
          </>
        )}
      </Box>

      <Box sx={{ padding: { lg: "40px", xs: "20px" }, paddingBottom: "0px" }}>
        <Typography
          component="p"
          variant="h3"
          sx={{
            mb: 2,
            textTransform: "uppercase",
            display: "block",
            textAlign: "center",
            fontSize: { md: "50px", xs: "15px" },
          }}
        >
          Revel in your style
        </Typography>

        <Box sx={{ width: { md: "900px", xs: "auto" }, mx: "auto" }}>
          <Grid
            container
            spacing={0}
            justifyContent="space-evenly"
            alignItems={"center"}
          >
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical1} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical2} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical3} height="400px" />
            </Grid>
          </Grid>
        </Box>

        <Box sx={{ width: { md: "1000px", xs: "auto" }, mx: "auto", mt: 1.5 }}>
          <Grid
            container
            spacing={0}
            justifyContent="space-evenly"
            alignItems={"center"}
          >
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical4} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical5} height="400px" />
            </Grid>
            <Grid item sx={{ textAlign: "center" }}>
              <Box component="img" src={Images.artical6} height="400px" />
            </Grid>
          </Grid>
        </Box>
      </Box>

      {/* Footer --------------------------------------------------------------------------------->  */}
      <Footer />
    </>
  );
}

export default Home;
