import { useState } from "react";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { ErrorToaster } from "../Toaster/toaster";
import { PayPalServices } from "app/apis/PayPal/PayPal.services";
import { findAllByTestId } from "@testing-library/react";
import { useDispatch } from "react-redux";
import { Stripekey } from "app/redux/slices/cartDetailSlice";

function PaypalForm({ punchOrder, Total }) {
  const dispatch = useDispatch();

  const [orderId, setOrderId] = useState(null);
  const [orderStatus, setorderStatus] = useState(false);

  const HandleApproveID = async (orderID) => {
    // Handle Backend APIf
    try {
      let formData = new FormData();
      formData.append("total", Total);
      const result = await PayPalServices.postPayPal(formData);
      if (result.responseCode === 200) {
        dispatch(Stripekey(result.data[0].key_id));
        punchOrder();
        setorderStatus(true);
      }
    } catch (e) {
      ErrorToaster(e);
    } finally {
    }
  };

  const handleOnApprove = async (data, actions) => {
    const order = await actions.order.capture();
    HandleApproveID(data.orderId);
  };

  return (
    <div>
      <PayPalButtons
        createOrder={(data, actions) => {
          return actions.order.create({
            purchase_units: [
              {
                description: "Hello Usman",
                amount: {
                  value: 1,
                },
              },
            ],
          });
        }}
        onClick={(date, actions) => { }}
        onApprove={handleOnApprove}
        // onClick={handleCreateOrder}
        onError={(err) => {
          ErrorToaster("Error occur");
        }}
        onCancel={() => { }}
      />
    </div>
  );
}

export default PaypalForm;
